export default {
	name: 'PersonData',

	data() {
		return {
			genders:[
				{id: 'M', visualName: "Hombre"},
				{id: 'F', visualName: "Mujer"},
			],
			documents: [
        // {text: "Carnet Identidad", value: 1},
        // {text: "DNI", value: 2},
        // {text: "Pasaporte", value: 3},
        // {text: "NIT", value: 4}
			],
			extensions: [],
			
			person: {
				nameMaxLength: 50,
				surnamesMaxLength: 100,
				birthdateMaxLength: 10,
				documentNumberMaxLength: 15,
				documentComplementMaxLength: 3,
				addressMaxLength: 250,
				phoneNumberMaxLength: 15,
				emailMaxLength: 100,
				cellPhoneNumberMaxLength: 15,
			},

			personValidationRules: {
				nameRules: {
          counter: value => (value.length <= this.person.nameMaxLength) || `No debe tener más de ${this.person.nameMaxLength} caracteres.`,
        },
        surnamesRules: {
          counter: value => (value.length <= this.person.surnamesMaxLength) || `No debe tener más de ${this.person.surnamesMaxLength} caracteres.`,
				},
				birthdateRules: {
          counter: value => (!!value && value.length <= this.person.birthdateMaxLength) || `No debe tener más de ${this.person.birthdateMaxLength} caracteres.`,
				},
				documentNumberRules: {
          counter: value => (!!value && value.length <= this.person.documentNumberMaxLength) || `No debe tener más de ${this.person.documentNumberMaxLength} caracteres.`,
				},
				documentComplementRules: {
          counter: value => (value.length <= this.person.documentComplementMaxLength) || `No debe tener más de ${this.person.documentComplementMaxLength} caracteres.`,
				},
        addressRules: {
          counter: value => (value.length <= this.person.addressMaxLength) || `No debe tener más de ${this.person.addressMaxLength} caracteres.`,
				},
				phoneNumberRules: {
          counter: value => (value.length <= this.person.phoneNumberMaxLength) || `No debe tener más de ${this.person.phoneNumberMaxLength} caracteres.`,
        },
        emailRules: {
          counter: value => (value.length <= this.person.emailMaxLength) || `No debe tener más de ${this.person.emailMaxLength} caracteres.`,
        },
        cellPhoneNumberRules: {
          counter: value => (!!value && value.length <= this.person.cellPhoneNumberMaxLength) || `No debe tener más de ${this.person.cellPhoneNumberMaxLength} caracteres.`,
				},
      }
		}
	},
}