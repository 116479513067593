<template>
	<v-container fluid fill-height>
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12">
        <base-heading :title="title">
          <base-btn v-if="customers.length>0" dark color="green darken-1" @click="mainReportView()" icon="mdi-file-chart" btnText="Reporte"></base-btn>
        </base-heading>
      </v-col>
    </v-row>
    <v-row>
			<v-col cols="12">
				<base-data-table 
          v-model="sortBy"
					:headers="headers" 
					:items="customers"
          :showDialog="dialog"
          showEnabledField
					@loadDataTable="toList" 
          @newItem="open"
        >
          <template v-slot:item.options="{ item }">
            <base-icon-btn @click="editItem(item)" icon="mdi-pencil" tooltip="Editar"></base-icon-btn>

            <template v-if="item.enabled">
              <base-icon-btn color="red darken-1" @click="enableDisableItem(item, 2)" icon="mdi-cancel" tooltip="Desactivar"></base-icon-btn>
            </template>
            <template v-else>
              <base-icon-btn color="green darken-1" @click="enableDisableItem(item, 1)" icon="mdi-check" tooltip="Activar"></base-icon-btn>
            </template>
          </template>

          <template v-slot:content>
            <base-form-simple ref="CustomerForm"
              :formTitle="formTitle"
              :formLoading="formLoading"
              :hideSaveButton="hideSaveButton"
              :cancelButtonText="cancelButtonText"
              @cancelButton="close"
              @saveButton="save"
            >
              <template v-slot:formContent>
                <v-container class="no-padding">
                  <template v-if="editData">
                    <v-row>
                      <v-col cols="12" sm="7" md="7">
                        <base-text-field v-model="editedItem.fullName" label="Nombre Completo" readonly></base-text-field>
                      </v-col>
                      <v-col cols="12" sm="5" md="5">
                        <base-number-field v-model="editedItem.generalDiscount" label="Descuento" counter maxlength="3"
                        suffix="%" :rules="[validationRules.generalDiscountRules.counter, validGeneralDiscount()]" required></base-number-field>
                      </v-col>
                    </v-row>
                  </template>

                  <template v-else>
                    <v-window v-model="step" touchless>
                      <v-window-item :value="1">
                        <transition name="slide-fade" mode="out-in">
                          <v-row v-if="selectForm" key="choice" align="center">
                            <v-col cols="12">
                              <v-hover v-slot:default="{ hover }">
                                <base-form-container
                                  :elevation="hover ? 10 : 0"
                                  title="Afiliación Rápida"
                                  color="grey lighten-4"
                                  titleClasses="grey lighten-3"
                                  outlined
                                  tile
                                >
                                  <template v-slot:content>
                                    <p class="text-justify">Para un registro rápido a su cartilla de clientes, ingrese el código <strong>Global Desk</strong> de la persona.</p>
                                    <v-row justify="center" align="center" no-gutters>
                                      <v-col cols="9" sm="4">
                                        <base-text-field v-model="gdpiCode" label="Código Global Desk" counter maxlength="30" 
                                        prepend-icon="mdi-shield-account"
                                        :rules="[commonValRules.required, validationRules.gdpiCodeRules.counter]" required clearable></base-text-field>
                                      </v-col>
                                      <v-col cols="3" sm="3" align="end">
                                        <base-btn enableSmallBtn color="secondary" @click="verifyCustomerByGDPI()" icon="mdi-account-check" btnText="Verificar"></base-btn>
                                      </v-col>

                                      <template v-if="searchPerson.exists">
                                        <v-col cols="9" sm="7">
                                          <base-text-field v-model="searchPerson.fullName" label="Nombre Completo" readonly></base-text-field>
                                        </v-col>
                                        <v-col cols="3" sm="3" align="end">
                                          <base-btn enableSmallBtn color="primary" @click="affiliate()" icon="mdi-account-plus" btnText="Afiliar"></base-btn>
                                        </v-col>
                                      </template>
                                    </v-row>
                                  </template>
										            </base-form-container>
                              </v-hover>
                            </v-col>
                            <v-col v-if="registrationLinkEnabled" cols="12">
                              <v-hover v-slot:default="{ hover }">
                                <base-form-container
                                  :elevation="hover ? 10 : 0"
                                  title="Enviar Enlace"
                                  color="grey lighten-4"
                                  titleClasses="grey lighten-3"
                                  outlined
                                  tile
                                >
                                  <template v-slot:content>
                                    <p class="text-justify">Genere y envíe un enlace directo al formulario de registro para que la persona se afilie a su cartilla de clientes.</p>
                                    <div class="text-center">
                                      <base-btn color="primary" @click="formChosen(1)" icon="mdi-send" btnText="Generar enlace"></base-btn>
                                    </div>
                                  </template>
										            </base-form-container>
                              </v-hover>
                            </v-col>
                            <v-col cols="12">
                              <v-hover v-slot:default="{ hover }">
                                <base-form-container
                                  :elevation="hover ? 10 : 0"
                                  title="Crear Cuenta"
                                  color="grey lighten-4"
                                  titleClasses="grey lighten-3"
                                  outlined
                                  tile
                                >
                                  <template v-slot:content>
                                    <p class="text-justify">Cree una cuenta <strong>Global Desk</strong> para la persona con la finalidad de registrarlo a su cartilla de clientes.</p>
                                    <div class="text-center">
                                      <base-btn color="primary" @click="formChosen(2)" icon="mdi-page-next-outline" btnText="Ir al formulario"></base-btn>
                                    </div>
                                  </template>
										            </base-form-container>
                              </v-hover>
                            </v-col>
                          </v-row>

                          <div v-else key="chosen">
                            <v-row v-if="linkForm" align="center">
                              <v-col cols="12" sm="4" md="4">
                                <v-card 
                                  class="pa-1"
                                  outlined
                                >
                                  <v-img :src="editedLink.qr"/>
                                </v-card>
                              </v-col>
                              <v-col cols="12" sm="8" md="8">
                                <v-row no-gutters align="center">
                                  <v-col cols="12" sm="12">
                                    <base-text-field
                                      v-model="editedLink.link"
                                      outlined
                                      label="Enlace Directo"
                                      :prepend-inner-icon="editedLink.link !== '' ? 'mdi-content-copy' : ''"
                                      @click:prepend-inner="copyLink"
                                      readonly
                                    ></base-text-field>
                                  </v-col>

                                  <v-col cols="12" align="center">
                                    <h3 class="pb-4">Envíe el enlace por correo electrónico a un destinatario</h3>
                                  </v-col>
                                  <v-col cols="12" sm="12">
                                    <base-text-field v-model="editedLink.name" label="Nombre del destinatario" counter maxlength="25" 
                                    :rules="[commonValRules.required, validationRules.linkNameRules.counter]" required></base-text-field>
                                  </v-col>
                                  <v-col cols="10" sm="9">
                                    <base-text-field v-model="editedLink.email" label="Correo electrónico" counter :maxlength="person.emailMaxLength" 
                                    :rules="[commonValRules.required, commonValRules.email, personValidationRules.emailRules.counter]" required></base-text-field>
                                  </v-col>
                                  <v-col cols="2" sm="3" align="end">
                                    <base-btn enableSmallBtn color="secondary" @click="sendLink()" icon="mdi-send" btnText="Enviar"></base-btn>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>

                            <template v-else>
                              <v-row v-if="searchPerson.checkEmail" justify="center" align="center">
                                <v-col cols="12" align="center">
                                  <p class="mb-0">Antes de crear una cuenta <strong>Global Desk</strong> necesitamos verificar los datos de la persona, por favor ingrese el correo electrónico de su nuevo cliente y presione el botón <b>verificar</b>.</p>
                                </v-col>
                                <v-col cols="10" sm="6" md="6">
                                  <base-text-field v-model="searchPerson.email" label="Correo Electrónico" counter :maxlength="person.emailMaxLength" 
                                  :rules="[commonValRules.required, commonValRules.email, personValidationRules.emailRules.counter]" required></base-text-field>
                                </v-col>
                                <v-col cols="2" sm="2" md="2" align="center">
                                  <base-btn enableSmallBtn color="secondary" @click="verifyCustomerByEmail()" icon="mdi-account-check" btnText="Verificar"></base-btn>
                                </v-col>

                                <template v-if="searchPerson.exists">
                                  <v-col cols="10" sm="7" md="7">
                                    <base-text-field v-model="searchPerson.fullName" label="Nombre Completo" readonly></base-text-field>
                                  </v-col>
                                  <v-col cols="2" sm="2" md="2">
                                    <base-btn enableSmallBtn color="primary" @click="affiliate()" icon="mdi-account-plus" btnText="Afiliar"></base-btn>
                                  </v-col>
                                </template>
                              </v-row>

                              <template v-else>
                                <v-row justify="center">
                                  <v-col cols="12" sm="4" md="4">
                                    <base-select v-model="editedItem.nationalityId" :items="nationalities" label="Nacionalidad" 
                                    :rules="[commonValRules.required]" required></base-select>
                                  </v-col>
                                  <v-col cols="12" sm="4" md="4">
                                    <base-text-field v-model="editedItem.name" label="Nombres" counter :maxlength="person.nameMaxLength" 
                                    :rules="[commonValRules.required, personValidationRules.nameRules.counter]" required></base-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="4" md="4">
                                    <base-text-field v-model="editedItem.surnames" label="Apellidos" counter :maxlength="person.surnamesMaxLength" 
                                    :rules="[commonValRules.required, personValidationRules.surnamesRules.counter]" required></base-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="4" md="4">
                                    <base-date-picker-dialog birthdateMode v-model="editedItem.birthdate" label="Fecha de Nacimiento" counter :maxlength="person.birthdateMaxLength" 
                                    :rules="[commonValRules.required, personValidationRules.birthdateRules.counter]" required></base-date-picker-dialog>
                                  </v-col>
                                  <v-col cols="12" sm="4" md="4">
                                    <base-label text="*Género">
                                      <v-radio-group class="mt-0 pt-0"
                                        v-model="editedItem.gender"
                                        row
                                        :rules="[commonValRules.required]" required
                                      >
                                        <v-radio v-for="gender in genders" :key="gender.id"
                                          :label="gender.visualName"
                                          :value="gender.id"
                                          color="info"
                                        ></v-radio>
                                      </v-radio-group>
                                    </base-label>
                                  </v-col>
                                </v-row>
                    
                                <v-row>
                                  <v-col cols="12" sm="4" md="4">
                                    <base-select v-model="editedItem.documentId" :items="documents" label="Tipo Documento"
                                    :rules="[commonValRules.required]" required></base-select>
                                  </v-col>
                                  <v-col cols="12" sm="3" md="3">
                                    <base-number-field fullWidth v-model="editedItem.documentNumber" label="Número Documento" counter :maxlength="person.documentNumberMaxLength"
                                    :rules="[personValidationRules.documentNumberRules.counter]" required></base-number-field>
                                  </v-col>
                                  <v-col cols="12" sm="2" md="2">
                                    <base-text-field v-model="editedItem.documentComplement" label="Complemento" counter :maxlength="person.documentComplementMaxLength"
                                    :rules="[personValidationRules.documentComplementRules.counter]"></base-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="3" md="3">
                                    <base-select v-model="editedItem.documentExtensionId" :items="documentExtensions" label="Extensión" 
                                    :rules="documentExtensionIdRules" :required="documentExtensionIsRequired" :disabled="!documentExtensionIsRequired"></base-select>
                                  </v-col>

                                  <v-col cols="12" sm="4" md="4">
                                    <base-number-field fullWidth v-model="editedItem.cellPhoneNumber" label="Número Celular" counter :maxlength="person.cellPhoneNumberMaxLength" 
                                    :rules="[personValidationRules.cellPhoneNumberRules.counter]" required></base-number-field>
                                  </v-col>
                                  <v-col cols="12" sm="8" md="8">
                                    <base-text-field v-model="editedItem.email" label="Correo Electrónico" counter :maxlength="person.emailMaxLength" 
                                    :rules="[commonValRules.required, commonValRules.email, personValidationRules.emailRules.counter]" required disabled></base-text-field>
                                  </v-col>
                                </v-row>
                              </template>
                            </template>
                          </div>
                        </transition>
                      </v-window-item>

                      <v-window-item :value="2">
                        <v-row justify="center">
                          <template v-if="linkForm">
                            <!-- <v-col cols="12" sm="12" md="12" align="center">
                              <strong>¡¡Correo electrónico enviado!!</strong>
                            </v-col>
                            <v-col cols="12" align="center">
                              <v-avatar>
                                <v-icon x-large color="blue darken-2">mdi-send</v-icon>
                              </v-avatar>
                            </v-col> -->
                            <v-col cols="12" align="center">
                              <p class="mb-0 text-justify">Se envió un correo electrónico a la dirección <strong>{{editedLink.email}}</strong> con el enlace directo al formulario de registro de clientes.</p>
                              <v-avatar class="mb-1">
                                <v-icon x-large color="blue darken-2">mdi-email-send-outline</v-icon>
                              </v-avatar>
                              <p class="mb-0 text-justify">Por favor comunique a su nuevo cliente que revise su bandeja de entrada y la bandeja de correo no deseado.</p>
                            </v-col>
                          </template>

                          <template v-else>
                            <!-- <v-col cols="12" sm="12" md="12" align="center">
                              <strong>¡¡El registro fue exitoso!!</strong>
                            </v-col>
                            <v-col cols="12" align="center">
                              <v-avatar>
                                <v-icon x-large color="blue darken-2">mdi-send</v-icon>
                              </v-avatar>
                            </v-col> -->
                            <v-col cols="12" align="center">
                              <!-- <p>Como último paso se envió un mensaje de notificación al correo electrónico <strong>{{editedItem.email}}</strong>.</p> -->
                              <p class="mb-0 text-justify">Se envió un correo electrónico a la dirección <strong>{{editedItem.email}}</strong> con los pasos a seguir para activar su cuenta <strong>Global Desk</strong>.</p>
                              <v-avatar class="mb-1">
                                <v-icon x-large color="blue darken-2">mdi-email-send-outline</v-icon>
                              </v-avatar>
                              <p class="mb-0 text-justify">Por favor comunique a su nuevo cliente que revise su bandeja de entrada y la bandeja de correo no deseado.</p>
                            </v-col>
                          </template>

                          <v-col cols="12" align="center">
                            ¡¡Muchas gracias por la confianza en nuestro equipo de trabajo!!
                          </v-col>
                        </v-row>
                      </v-window-item>
                    </v-window>
                  </template>
                </v-container>
              </template>
            </base-form-simple>
          </template>
				</base-data-table>
			</v-col>
		</v-row>

    <base-pdf-viewer :show="showPdfViewer" :title="title" :src="reportFile.fileContent" :fileName="reportFile.fileName" @close="closeViewer()"/>
	</v-container>
</template>

<script>
// Mixins
import MainExternalData from '@/mixins/main-external-data'
import PersonData from '@/mixins/person-data'

export default {
	mixins: [MainExternalData, PersonData],

	data() {
		return {
      title: 'Clientes',
      step: 1,
			sortBy: 'fullName',
			customers: [],
			headers: [
				{ text: "Opciones", value: "options", sortable: false },
				{ text: "Cliente", value: "fullName" },
        { text: "Número Celular", value: "cellPhoneNumber", sortable: false },
        { text: "Estado", value: "enabled" }
      ],
      nationalities:[],
      documentExtensionIsRequired: false,
			editedItem: {
				id: '',
        nationalityId: '',
        fullName:'',
        name: '',
        surnames: '',
        gender:'',
				birthdate: '',
        documentId: '',
        documentNumber: '',
        documentComplement: '',
        documentExtensionId: '',
        cellPhoneNumber: '',
        email: '',

        generalDiscount: 0
      },
      defaultItem: {
        id: '',
        nationalityId: '',
        fullName:'',
        name: '',
        surnames: '',
        gender:'',
				birthdate: '',
        documentId: '',
        documentNumber: '',
        documentComplement: '',
        documentExtensionId: '',
        cellPhoneNumber: '',
        email: '',
        
        generalDiscount: 0
      },

      registrationLinkEnabled: false,
      editedLink: {
        name: '',
        email: '',
				link: '',
        qr: ''
      },
      defaultLink: {
        name: '',
        email: '',
				link: '',
        qr: ''
      },

      searchPerson: {
        gdpi: '',
        email: '',
        fullName: '',
				checkEmail: true,
        exists: false
      },
      defaultSearchPerson: {
        gdpi: '',
        email: '',
        fullName: '',
				checkEmail: true,
        exists: false
      },

      gdpiCode:'',
      editData: false,
      dialog: false,
      formLoading: false,
      selectForm: true,
      hideSaveButton:true,
      cancelButtonText:'Cancelar',
      linkForm: true,  
      validationRules: {
        gdpiCodeRules: {
          counter: value => (!!value && value.length <= 30) || 'No debe tener más de 30 caracteres.',
				},

        generalDiscountRules: {
					counter: value => (value != null && value.toString().length <= 3) || 'No debe tener más de 3 caracteres.',
					valid: value => (parseInt(value) >= 0 && parseInt(value) <= 100) || 'El valor debe ser entre 0 y 100.',
				},

        linkNameRules: {
          counter: value => (value.length <= 25) || 'No debe tener más de 25 caracteres.',
        },
      },

      //PDF report
			showPdfViewer: false,
			reportFile:{
				fileContent:'',
				fileName:''
			},
			defaultReportFile:{
				fileContent:'',
				fileName:''
			},
		}
  },
  
  computed: {
		formTitle() {
      return this.editData ? "Actualizar datos" : "Nuevo registro";
    },

    documentExtensions() {
			return this.editedItem.documentId == this.$Const.DOCUMENT_TYPE.CI ? this.extensions : [];
    },

    documentExtensionIdRules(){
      var rules=[];
			if (this.editedItem.documentId === this.$Const.DOCUMENT_TYPE.CI) {
        rules.push(this.commonValRules.required);
        this.documentExtensionIsRequired = true;
      }
      else {
        this.editedItem.documentExtensionId = '';
        this.documentExtensionIsRequired = false;
      }
			return rules;
		},
	},

	created() {
    this.getCustomerFormStatus();
    this.listOfParameters();
    this.listOfNationalities();
		this.toList();
  },

	methods: {
    async listOfParameters(){
      let me=this;
			await me.getListOfParameterValuesResponse([me.$Const.PARAMETERS.DOCUMENTS, me.$Const.PARAMETERS.EXTENSIONS]).then(data => {
        if (data !== undefined) {
          let documentArray = data.find(x => x.id == me.$Const.PARAMETERS.DOCUMENTS).values;
          documentArray.map(function(x){
            me.documents.push({text:x.visualName, value:x.id});
          });

          let extensionsArray = data.find(x => x.id == me.$Const.PARAMETERS.EXTENSIONS).values;
          extensionsArray.map(function(x){
            me.extensions.push({text:x.visualName, value:x.id});
          });
        }
      }).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
    },

    async listOfNationalities(){
      let me=this;
			var nationalitiesArray=[];
			await me.getObjectResponse('api/Parameter/Nationalities', me.baseRequest).then(data => {
				if (data !== undefined) {
					nationalitiesArray = data.nationalities; 
					nationalitiesArray.map(function(x){
            me.nationalities.push({text:x.visualName, value:x.id});
          });
				}
			}).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
    },

		async toList(){
			let me=this;
      try {
        me.SHOW_LOADING()
        me.customers=[];
        await me.getObjectResponse('api/Customer/GetAll', me.baseRequest).then(data => {
          if (data !== undefined) {
            me.customers = data.customers; 
          }
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},
		
		async editItem(item) {
      let me=this;
      try {
        me.SHOW_LOADING()
        let request={
          'companyId': me.baseRequest.companyId,
          'userId': me.baseRequest.userId,
          'id': item.id,
        };
        await me.getObjectResponse('api/Customer/GetById', request).then(data => {
          if (data === undefined) {
            return;
          }
          me.editedItem = Object.assign({}, data.customer);
          me.editData = true;
          me.hideSaveButton=false;
          me.cancelButtonText='Cancelar';
          me.dialog = true;
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
    },

    validGeneralDiscount(){
			if (parseInt(this.editedItem.generalDiscount) >= 0 && parseInt(this.editedItem.generalDiscount) <= 100) {
				return true;	
			}
			this.editedItem.generalDiscount = '';
			return 'El valor debe ser entre 0 y 100.'
		},

    open(){
      this.dialog = true;
      this.editData = false;
    },

    close() {
      this.dialog = false;
      this.clean();
    },
    
    clean(){
      this.step = 1;
      // this.editData = false;
      this.$refs.CustomerForm.reset();
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedLink = Object.assign({}, this.defaultLink);
      this.searchPerson = Object.assign({}, this.defaultSearchPerson);
      this.gdpiCode = '';
      this.selectForm = true;
      this.hideSaveButton = true;
      this.cancelButtonText = 'Cancelar';
      this.linkForm = true;
    },

    async verifyCustomerByEmail(){
      let me=this;
      if (!me.$refs.CustomerForm.validate()) {
        return;
      }

      try {
        me.SHOW_LOADING()
        me.formLoading=true;
        let request={
          'companyId': me.baseRequest.companyId,
          'userId': me.baseRequest.userId,
          'email': me.searchPerson.email,
        };
        me.formLoading=true;
        await me.getObjectResponse('api/Customer/CheckIfExistsByEmail', request).then(data => {
          if (data === undefined) {
            return;
          }
          me.searchPerson.gdpi = data.gdpi;
          me.searchPerson.fullName = data.fullName;
          me.searchPerson.exists = data.exists;
          if (!data.exists) {
            me.searchPerson.checkEmail = false;
            me.editedItem.email = me.searchPerson.email;
            me.hideSaveButton = false;
          }
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
        me.formLoading=false;
      }
    },

    async verifyCustomerByGDPI(){
      let me=this;
      if (!me.$refs.CustomerForm.validate()) {
        return;
      }

      try {
        me.SHOW_LOADING()
        me.formLoading=true;
        let request={
          'companyId': me.baseRequest.companyId,
          'userId': me.baseRequest.userId,
          'gdpi': me.gdpiCode,
        };
        me.formLoading=true;
        await me.getObjectResponse('api/Customer/CheckIfExistsByGDPI', request).then(data => {
          if (data === undefined) {
            return;
          }
          me.searchPerson.gdpi = me.gdpiCode;
          me.searchPerson.fullName = data.fullName;
          me.searchPerson.exists = data.exists;
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
        me.formLoading=false;
      }
    },

    async affiliate(){
      let me=this;
      if (!me.$refs.CustomerForm.validate()) {
        return;
      }

      try {
        me.SHOW_LOADING()
        me.formLoading=true;
        let request={
          'companyId': me.baseRequest.companyId,
          'userId': me.baseRequest.userId,
          'gdpi': me.searchPerson.gdpi,
        };
        me.formLoading=true;
        await me.getObjectResponse('api/Customer/RegisterUsingGDPI', request).then(data => {
          if (data === undefined) {
            return;
          }
          me.addOrUpdateListItem(data.customer);
          me.showSuccessModal();
          me.close();
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
        me.formLoading=false;
      }
    },

    async formChosen(value){
      if (value===1) {
        await this.getLink();
      }
      else if (value===2) {
        this.searchPerson.exists=false;
        this.selectForm=false;
        this.linkForm=false;
      }
      else{
        this.selectForm=false;
        this.linkForm=false;
        this.hideSaveButton=false;
      }
    },

    async getCustomerFormStatus(){
      let me=this;
      let request={
        'companyId': me.baseRequest.companyId,
        'userId': me.baseRequest.userId
      };
      await me.getObjectResponse('api/Company/CustomerFormStatus', request).then(data => {
        if (data === undefined) {
					return;
        }
        me.registrationLinkEnabled = data.enabled;
      }).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
    },

    async getLink(){
      let me=this;

       try {
        me.SHOW_LOADING()
        me.formLoading=true;
        let request={
          'companyId': me.baseRequest.companyId,
          'userId': me.baseRequest.userId,
          'getQR': true
        };
        await me.getObjectResponse('api/Company/GetCustomerFormLink', request).then(data => {
          if (data === undefined) {
            return;
          }
          me.editedLink.link = data.link;
          me.editedLink.qr = data.qr;
          me.linkForm=true;
          me.selectForm=false;
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
        me.formLoading=false;
      }
    },

    async sendLink(){
      if (!this.$refs.CustomerForm.validate()) {
        return;
      }

      let me=this;
      try {
        me.SHOW_LOADING()
        me.formLoading=true;
        let request={
          'companyId': me.baseRequest.companyId,
          'userId': me.baseRequest.userId,
          'name': me.editedLink.name,
          'email': me.editedLink.email,
        };
        await me.getObjectResponse('api/Customer/SendLink', request).then(data => {
          if (data === undefined) {
            return;
          }
          me.cancelButtonText='Cerrar';
          me.step=2;
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
        me.formLoading=false;
      }
    },

    copyLink(){
      let me = this;
      navigator.clipboard.writeText(me.editedLink.link);
      me.$swal({ toast: true, position: 'top-end', icon: 'success', title: 'Enlace copiado.', timer: me.swalConfig.timer, showConfirmButton: false });
    },

    async save() {
      let me=this;
      try {
        me.SHOW_LOADING()
        me.formLoading=true;
        if (me.editData) {
          let request={
            'companyId': me.baseRequest.companyId,
            'userId': me.baseRequest.userId,
            'id': me.editedItem.id,
            'generalDiscount': parseInt(me.editedItem.generalDiscount)
          };
          await me.getBaseResponse('api/Customer/Update', request).then(data => {
            if (data) {
              me.close();
              me.showSuccessModal();
            }
          }).catch(function(error){
            me.$swal(me.swalConfig.errorTitle, error.message, 'error');
          });
        } 
        else {
          let request={
            'companyId': me.baseRequest.companyId,
            'userId': me.baseRequest.userId,

            'nationalityId': me.editedItem.nationalityId,
            'name': me.editedItem.name,
            'surnames': me.editedItem.surnames,
            'gender': me.editedItem.gender,
            'birthdate': me.editedItem.birthdate,
            'documentId': me.editedItem.documentId,
            'documentNumber': me.editedItem.documentNumber,
            'documentComplement': me.editedItem.documentComplement,
            'documentExtensionId': me.editedItem.documentExtensionId,
            'cellPhoneNumber': me.editedItem.cellPhoneNumber,
            'email': me.editedItem.email
          };
          await me.getObjectResponse('api/Customer/Create', request).then(data => {
            if (data === undefined) {
              return;
            }
            me.addOrUpdateListItem(data.customer);
            me.showSuccessModal();
            me.hideSaveButton=true;
            me.cancelButtonText='Cerrar';
            me.step=2;
          }).catch(function(error){
            me.$swal(me.swalConfig.errorTitle, error.message, 'error');
          });
        }
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
        me.formLoading=false;
      }
    },

    addOrUpdateListItem(item){
			let me=this;
			if (!!me.customers.find((x) => x.id === item.id)) {
				const index = me.customers.findIndex((x) => x.id === item.id)
				me.customers.splice(index, 1, item)
			} else {
				me.customers.push(item);
			}
		},

    async enableDisableItem(item, action){
			let me = this;
			if (!await me.showEnableDisableConfirmDialog(action, `el cliente <strong>${item.fullName}</strong>`)) {
				return;
			}

			if (action==1) {
				me.enable(item);
			}
			else if (action==2) {
				me.disable(item);
			}
		},

    async enable(item){
      let me=this;
      try {
        me.SHOW_LOADING()
        let request={
          'companyId': me.baseRequest.companyId,
          'userId': me.baseRequest.userId,
          'id': item.id,
        };
        await me.getBaseResponse('api/Customer/Enable', request).then(data => {
          if (data) {
            item.enabled = true;
            me.showSuccessModal();
          }
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
    },

    async disable(item){
      let me=this;
      try {
        me.SHOW_LOADING()
        let request={
          'companyId': me.baseRequest.companyId,
          'userId': me.baseRequest.userId,
          'id': item.id,
        };
        await me.getBaseResponse('api/Customer/Disable', request).then(data => {
          if (data) {
            item.enabled = false;
            me.showSuccessModal();
          }
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
    },

    async mainReportView() {
			let me=this;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
				};
				await me.getObjectResponse('api/Customer/GetMainReportView', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.reportFile = Object.assign({}, data);
					me.showPdfViewer=true;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},

    closeViewer() {
			this.reportFile = Object.assign({}, this.defaultReportFile);
			this.showPdfViewer = false;
		},
	},
}
</script>

<style scoped>
/* Las animaciones de entrada y salida pueden usar */
/* funciones de espera y duración diferentes.      */
.slide-fade-enter-active {
  transition: all .6s ease;
}

.slide-fade-leave-active {
  transition: all .6s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>